<template>
  <Loader :key="$path('title', post)" :value="promises.fetch" class="forbehold-page">
    <Blocks v-if="item.posts" :blocks="config" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

// pagebuilder
import Config from '@/config/policy'
import { Blocks } from '@kvass/pagebuilder'

export default {
  computed: {
    ...mapState('Root', ['promises', 'item']),
    config() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || ['subpage']
      return Config[sources[0]].call(this)
    },
    post() {
      if (!this.item.posts) return {}

      const post = this.item.posts.find(p => p.slug === 'forbehold')

      if (!post) {
        this.$router.push({ name: 'Project' })
      }

      return post
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.forbehold-page {
  .forbehold {
    max-width: 37.5rem;
    margin: 0 auto;

    ul {
      padding: 0 0 0 2.5rem;
    }
    h1 {
      padding-bottom: 3rem;
      font-weight: normal;
    }
  }
}
</style>
